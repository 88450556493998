/* --- TOP BAR --- */
.top-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #2a2a2a;
}

.top-bar > a {
  margin-right: auto;
  font-weight: bold;
}

.top-bar-selectors {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: flex-end;
}

.top-bar-selectors a {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 769px) {
  .top-bar {
    flex-direction: column;
  }
}

/* --- CENTER SELECTION --- */
.center-selection {
  position: relative;
}

.center-selection i {
  cursor: pointer;
}

.center-selection .center-list {
  display: none;
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: black;
  padding: 0;
  z-index: 2;
  width: max-content;
}

.center-selection .center-list.opened {
  display: block;
}

.center-option {
  padding: 5px;
}

.center-option:hover {
  cursor: pointer;
  background-color: rgb(215, 215, 215);
}

/* --- MENU --- */
.menu {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 7px;
  background-color: #333;
}

.menu a {
  padding: 5px 10px;
}

.menu a:hover {
  background-color: #ddd;
  color: black;
}

@media screen and (max-width: 769px) {
  .menu {
    flex-direction: column;
    padding: 10px 0;
    gap: 0;
  }

  .menu a {
    display: none;
    padding: 10px;
  }

  .menu a:first-of-type {
    margin-top: 10px;
  }
}

.menu.is-open a {
  display: block;
}

/* --- HAMBURGER ICON --- */
.hamburger-icon {
  display: none;
}

@media screen and (max-width: 769px) {
  .hamburger-icon {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
}

/* --- WITH-DIALOG OPTION --- */
.with-dialog {
  background-color: #c00d0d;
  color: #fff;
}

.with-dialog a:hover {
  color: #000;
}

.menu.with-dialog {
  background-color: #171717;
}
