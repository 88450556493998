body {
  color: whitesmoke;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  background-color: #181512;
}

body.with-dialog {
  background-color: #000;
  color: #fff;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar {
  appearance: none;
  display: none;
  -webkit-appearance: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#root::-webkit-scrollbar {
  appearance: none;
  display: none;
  -webkit-appearance: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#root {
  width: 100%;
  height: auto;
  position: absolute;
}

footer {
  margin: 0;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: #111;
  padding: 2rem;
}

footer > a:any-link {
  color: #fff;
}

/* container holds the webshop iframe */

.container {
  display: grid;
  gap: 2px;
  width: 100vw;
  height: 100%;
}

.iframe-settings {
  color: #f2f2f2;
  width: 100%;
  background-color: #333;
  overflow: hidden;
  padding-bottom: 10pt;
  display: flex;
  justify-content: space-evenly;
}

.iframe-settings.with-dialog {
  background-color: #c00d0d;
  color: #fff;
}

.frame {
  grid-area: 'webshop';
}

/* calculate fix performance so we get a solid breakpoint in iframe at 1024 px */

/* for laptop 1366 px width  */

@media only screen and (max-width: 1365px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-areas: 'webshop';
  }

  .frame {
    width: 100vw;
  }
}

@media only screen and (min-width: 1365px) {
  .frame {
    width: 100%;
  }
}

.iframe-webshop {
  width: 100%;
  border: 0px;
}

.ui.menu {
  width: 100%;
  margin: 0 auto 2px auto;
}

a:any-link {
  color: whitesmoke;
}

.product-input-block {
  margin: 20px;
}

.product-input-block input {
  height: 30px;
  margin-right: 10px;
  font-size: 20px;
}

.product-input-block button {
  height: 36px;
  font-size: 20px;
  padding: 0 15px;
}
