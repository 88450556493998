.film-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 10px;
}

@media only screen and (min-width: 630px) {
  .film-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1000px) {
  .film-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.film {
  padding: 10px;
  border-radius: 3px;
}

.film-title {
  margin: 0 0 10px 0;
  text-align: center;
}

.film-info-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.film-info-wrapper > img {
  max-width: 100px;
}

.film-performances-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
}

@media only screen and (min-width: 400px) {
  .film-title {
    text-align: start;
  }

  .film-info-wrapper {
    align-items: flex-start;
    flex-direction: row;
  }

  .film-performances-list {
    justify-content: flex-start;
  }
}

.film-performance {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #666;
  text-align: center;
  background: #444;
  transition: all 0.3s;
}

.film-performance:hover {
  background: #646464;
}

.film-performance > a > p {
  margin: 0 0 4px 0;
  font-size: 14px;
}

.film-performance > a > p:last-of-type {
  margin: 0;
}
